import React from 'react'
import { graphql, Link } from 'gatsby';
import RenderSlices from '../01 Atoms/slicesHelper'
import Layout from '../04 Templates/layout'

export const query = graphql`
query NewsPageQuery($uid: String!) {
    prismic {
        allNews_contents(uid: $uid) {
          edges {
            node {
              hero_image
              hero_imageSharp {
                childImageSharp {
                  fluid(maxWidth: 2000) {
                      ...GatsbyImageSharpFluid
                  }
                }
              }              
              introduction
              title
              _meta {
                tags
                uid
                type
              }
              body {
                ... on PRISMIC_News_contentBodyContent {
                  type
                  label
                  primary {
                    content
                  }
                }
                ... on PRISMIC_News_contentBodyInline_image {
                  type
                  label
                  primary {
                    image
                    imageSharp {
                      childImageSharp {
                        fluid(maxWidth: 1200) {
                            ...GatsbyImageSharpFluid
                        }
                      }
                    }                    
                    caption
                  }
                }                  
              }
            }
          }
        }
      }
    }
`


  
  const RenderBody = ({ home, slices }) => (
    <React.Fragment>
      <article>
        <RenderSlices slices={home.node} />
        <section className="homepage-slice-wrapper return">
          <div className="pagination return">
            <Link to="/news-travel-tips/">
              <span className="cta--back">More news & travel tips</span> 
            </Link>
          </div>
        </section>
      </article>
    </React.Fragment>
  );
  
  const Content = data => {
    const doc = data.data.prismic.allNews_contents.edges.slice(0,1).pop();
    if(!doc) return null;
    return(
      <Layout data={doc}>
          <RenderBody home={doc}/>
      </Layout>
    )
  }
  
  export default Content;